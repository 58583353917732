import { render, staticRenderFns } from "./WelcomeEmailTabs.vue?vue&type=template&id=45a0d8cd&scoped=true&"
import script from "./WelcomeEmailTabs.vue?vue&type=script&lang=js&"
export * from "./WelcomeEmailTabs.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeEmailTabs.vue?vue&type=style&index=0&id=45a0d8cd&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a0d8cd",
  null
  
)

export default component.exports