<template>
	<aside :class="['dker', $store.state.WhiteLabel ? '' : 'bg-dark-blue', 'aside-xl']" style="position: sticky; top: 0px;" v-if="reloadLeftSidebarCompenent"> <!--  aside-xl -->
		<section class="vbox" style="position:absolute;width: 200px;">
			<header :class="[$store.state.WhiteLabel ? '' : 'bg-dark-bule-2 nav-bar']">
				<router-link  :to="{ name : 'myLeads' }" class="nav-brand logo_dark d-flex" style="padding: 0px 4px 0px;">
					<template v-if="$store.state.DefaultLogo != ''">
						<img style="width: 80%;display: block;margin: auto;" :src="$store.state.DefaultLogo" alt="" v-if="$store.state.WhiteLabel" />
						<img style="width: 80%;display: block;margin: auto;" src="img/svg/logo-white.svg" alt="" v-else />
					</template>
				</router-link>
			</header>
			<section>
				<nav :class="['hidden-xs oml-leftSidbar-nav', $store.state.WhiteLabel ? '' : 'bg-dark-bule-2']">
					<ul class="nav-scrollable-md">
						<router-link :to="{ path : '/newsource' }" class="btn btn-danger btn-block mb-2 btn-newsource" id="button-add-new-source" v-if="!$store.state.active || ($store.state.active && 'add sources' in $store.state.permissions && $store.state.active && 'list sources' in $store.state.permissions )">
							<img src="/img/svg/plus1.svg" class="arrow-up" style="width: 20px;" /> <span>{{ $t('NewSources') }}</span>
						</router-link>
						<!--li>
							<router-link  :to="{ name : this.$auth.user().role === 'sysadmin' ? 'admin.dashboard' : 'dashboard' }" class="menu-collapse-md">
								<span>Dashboard</span>
							</router-link>
						</li-->
						<span v-if="!$store.state.WhiteLabel">
							<li  v-for="(section, key) in sidebar_menu" v-bind:key="key" :id="section.title">
								<template v-if="section.children.length > 0">
									<div class="menu-collapse-md" @click="section.fade = !section.fade"
										:style="{ color: $store.state.WhiteLabel ? '#000000' : '#eee' }">
										<span>{{ $t(section.title) }}</span>
										<span style="float: right;">
											<!--i :class="['fa', section.fade ? 'fa-angle-up' : 'fa-angle-down']"></i-->
											<img v-if="section.fade" src="/img/svg/arrow-up.svg" class="arrow-up" />
											<img v-else src="/img/svg/arrow-down.svg" class="arrow-up" />
										</span>
									</div>
									<ul class="menu-items-md" v-show="section.fade">
										<!--li v-if="section.title == 'Settings'" @click="$bvModal.show('modal-profile-setting')">
											<span class="menu-icon c-pointer"><i class="fa fa-user"></i></span>
											<span :class="['menu-title c-pointer', $store.state.selectedPage == 'Profile' ? 'active' : '']">Profile</span>
										</li-->
										<li v-for="(child, id) in section.children" v-bind:key="id" @click="$store.state.selectedPage = child.page" :id="child.page">
											<router-link :to="{ path : '/' + child.path + ( ['settings', 'integration'].includes(child.path) ? '/' + child.page : '' ), params: {page: child.page} }">
												<span class="menu-icon">
													<img v-if="child.img != ''" :src="['/img/svg/' + child.img]" />
													<i v-else :class="['fa', child.icon]" ></i>
												</span>
												<span :class="['menu-title', $store.state.selectedPage == child.page ? 'active' : '']"
													:style="{ color: $store.state.WhiteLabel ? '#000000' : '#dedede' }">
													{{ $t(child.title) }}
													<span class="badge badge-md" v-if="child.title == 'LeadSources'">{{ sourcesCount }}</span>
													<span class="badge badge-md" v-if="child.title == 'MyLeads'">{{ leadsCountSide }}</span>
												</span>
											</router-link>
										</li>
										
									</ul>
								</template>
								<template v-else>
									<div class="pt-3" @click="[$store.state.selectedPage = section.page, showLeadsViewFun(section.page)]">
										<router-link :to="{ path : '/' + section.url }" class="li-tab-md menu-items-md" :id="section.page">
											<!--span class="text-li-tab">{{ section.title }}</span-->

											<span class="menu-icon">
												<img v-if="section.icon != ''" :src="['/img/svg/' + section.icon]" />
												<i v-else :class="['fa', section.icon]" style="color:#fff"></i>
											</span>
											<span :class="['menu-title', $store.state.selectedPage == section.page ? 'active' : '']"
												:style="{ color: $store.state.WhiteLabel ? '#000000' : '#dedede' }">
												{{ $t(section.title) }}
												<span class="badge badge-md" v-if="section.title == 'LeadSources'">{{ sourcesCount }}</span>
												<span class="badge badge-md" v-if="section.title == 'MyLeads'">{{ leadsCountSide }}</span>
											</span>
										</router-link>
									</div>
									
									<template v-if="section.page == 'myLeads' && showLeadsView">
										<div class="oml-smartViews-sidebar">
											<div class="oml-smartViewsSidebar-header">
												<h6>SMART VIEWS</h6>
												<button @click="searchView = true"><i class="ri-search-line"></i></button>
												<transition name="slide-fade">
													<div :class="[searchView ? 'oml-smartViewsSidebar-search show' : 'oml-smartViewsSidebar-search']" v-if="searchView">
														<input type="text" v-model="searchSmartView" @keydown="search()" placeholder="Search ..."/>
														<button @click="searchView = false;close()"><i class="ri-close-line"></i></button>
													</div>
												</transition>
											</div>
											<div class="oml-smartViewsSidebar-content">
												<draggable
													:list="smartViews"
													:disabled="!enabled"
													class="list-group"
													ghost-class="ghost"
													@start="dragging = true"
													@end="dragging = true"
													@change="saveStatusChanges"
												>
													<div :class="[item.status == 0 ? 'oml-smartViewsSidebar-item' : 'oml-smartViewsSidebar-item default-item',$store.state.smartViewsItem.id == item.id ? 'active' : '']" v-for="(item,index) in smartViews" :key="index">
														<div class="oml-smartViews-left" @click="sendFilter(item,key)">
															<i class="ri-apps-2-fill"></i>
															<span style="cursor: pointer;"><template v-if="item.name == 'This year' || item.name == 'Cette année'">Unassigned</template><template v-else>{{item.name}}</template></span>
														</div>
														<div class="oml-smartViews-right">
															<span>{{item.val}}</span>
															<!--button><i class="ri-edit-box-line" @click="editSmartView(item)" v-b-modal="'editView'" v-if="item.status == 0"></i></button>
															<button><i class="ri-delete-bin-line" v-b-modal="'deleteView'" @click="deleteSmartViewModal(item)" v-if="item.status == 0"></i></button-->
															<button><i class="ri-edit-box-line" @click="editSmartView(item)" v-b-modal="'editView'"></i></button>
															<button><i class="ri-delete-bin-line" v-b-modal="'deleteView'" @click="deleteSmartViewModal(item)"></i></button>
														</div>
													</div>
												</draggable>
											</div>
											<!--
											<button class="oml-smartViewsSidebar-showMore">
												<span><i class="ri-restart-line"></i>Show more ...</span>
											</button>
											-->
										</div>
									</template>
								</template>
							</li>
						</span>
						<span v-if="$store.state.WhiteLabel">
							<li  v-for="(section, key) in sidebar_menu_white" v-bind:key="key" :id="section.title">
								<template v-if="section.children.length > 0">
									<div class="menu-collapse-md" @click="section.fade = !section.fade"
										:style="{ color: $store.state.WhiteLabel ? '#000000' : '#eee' }">
										<span>{{ $t(section.title) }}</span>
										<span style="float: right;">
											<!--i :class="['fa', section.fade ? 'fa-angle-up' : 'fa-angle-down']"></i-->
											<img v-if="section.fade" src="/img/svg/warrow-up.svg" class="arrow-up" />
											<img v-else src="/img/svg/warrow-down.svg" class="arrow-up" />
										</span>
									</div>
									<ul class="menu-items-md" v-show="section.fade">
										<!--li v-if="section.title == 'Settings'" @click="$bvModal.show('modal-profile-setting')">
											<span class="menu-icon c-pointer"><i class="fa fa-user"></i></span>
											<span :class="['menu-title c-pointer', $store.state.selectedPage == 'Profile' ? 'active' : '']">Profile</span>
										</li-->
										<li v-for="(child, id) in section.children" v-bind:key="id" @click="$store.state.selectedPage = child.page" :id="child.page">
											<router-link :to="{ path : '/' + child.path + ( ['settings', 'integration'].includes(child.path) ? '/' + child.page : '' ), params: {page: child.page} }" :id="section.page">
												<span class="menu-icon">
													<img v-if="child.img != ''" :src="['/img/svg/' + child.img]" />
													<i v-else :class="['fa', child.icon]" style="color:#000"></i>
												</span>
												<span :class="['menu-title', $store.state.selectedPage == child.page ? 'active' : '']"
													:style="{ color: $store.state.WhiteLabel ? '#000000' : '#dedede' }">
													{{ $t(child.title) }}
													<span class="badge badge-md" v-if="child.title == 'LeadSources'">{{ sourcesCount }}</span>
													<span class="badge badge-md" v-if="child.title == 'MyLeads'">{{ leadsCountSide }}</span>
												</span>
											</router-link>
										</li>
									</ul>
								</template>
								<template v-else>
									<div class="pt-3" @click="$store.state.selectedPage = section.page">
										<router-link :to="{ path : '/' + section.url }" class="li-tab-md menu-items-md" :id="section.page">
											<!--span class="text-li-tab">{{ section.title }}</span-->

											<span class="menu-icon">
												<img v-if="section.icon != ''" :src="['/img/svg/' + section.icon]" />
												<i v-else :class="['fa', section.icon]" style="color:#fff"></i>
											</span>
											<span :class="['menu-title', $store.state.selectedPage == section.page ? 'active' : '']"
												:style="{ color: $store.state.WhiteLabel ? '#000000' : '#dedede' }">
												{{ $t(section.title) }}
												<span class="badge badge-md" v-if="section.title == 'LeadSources'">{{ sourcesCount }}</span>
												<span class="badge badge-md" v-if="section.title == 'MyLeads'">{{ leadsCountSide }}</span>
											</span>
										</router-link>
									</div>
								</template>
							</li>
						</span>
					</ul>
					<ul class="nav-scrollable-md oml-bottomNav">
						<span v-if="!$store.state.WhiteLabel">
							<li  v-for="(section, key) in sidebar_menu_down" v-bind:key="key" :id="section.title">
								<template>
									<div class="pt-3" @click="[$store.state.selectedPage = section.page, showLeadsViewFun(section.page)]">
										<router-link :to="{ path : '/' + section.url }" class="li-tab-md menu-items-md" :id="section.page">
											<span class="menu-icon">
												<img v-if="section.icon != ''" :src="['/img/svg/' + section.icon]" />
												<i v-else :class="['fa', section.icon]" style="color:#fff"></i>
											</span>
											<span :class="['menu-title', $store.state.selectedPage == section.page ? 'active' : '']"
												:style="{ color: $store.state.WhiteLabel ? '#000000' : '#dedede' }">
												{{ $t(section.title) }}
												<span class="badge badge-md" v-if="section.title == 'LeadSources'">{{ sourcesCount }}</span>
												<span class="badge badge-md" v-if="section.title == 'MyLeads'">{{ leadsCountSide }}</span>
											</span>
										</router-link>
									</div>
								</template>
							</li>
						</span>
						<span v-if="$store.state.WhiteLabel">
							<li  v-for="(section, key) in sidebar_menu_down" v-bind:key="key" :id="section.title">
								<div class="pt-3" @click="$store.state.selectedPage = section.page">
									<router-link :to="{ path : '/' + section.url }" class="li-tab-md menu-items-md" :id="section.page">
										<!--span class="text-li-tab">{{ section.title }}</span-->
										<span class="menu-icon">
											<img v-if="section.icon != ''" :src="['/img/svg/' + section.icon]" />
											<i v-else :class="['fa', section.icon]" style="color:#fff"></i>
										</span>
										<span :class="['menu-title', $store.state.selectedPage == section.page ? 'active' : '']"
											:style="{ color: $store.state.WhiteLabel ? '#000000' : '#dedede' }">
											{{ $t(section.title) }}
											<span class="badge badge-md" v-if="section.title == 'LeadSources'">{{ sourcesCount }}</span>
											<span class="badge badge-md" v-if="section.title == 'MyLeads'">{{ leadsCountSide }}</span>
										</span>
									</router-link>
								</div>
							</li>
						</span>
					</ul>
				</nav>
			</section>
		</section>
		<b-modal id="addView" size="md" hide-footer @hidden="close('addView')"  title="Add view">

			<div class="form-row m-0">
				<div class="form-group col-md-12 pl-0">
					<label for="name">Name <b style="color:red;">*</b></label>
					<input type="text" class="form-control " placeholder="" />
					<!--<small style="color: red;"> * Name required</small>-->
					<!--:placeholder="$t('write_the_email_reason_here')"-->
				</div>


				<div class="form-group col-md-12 pl-0">
					<div class="modal-actions">
						<button class="oml-btn outlined gray-btn mr-3" >Cancel</button>
						<button class="oml-btn" >Save</button>
					</div>
				</div>
			</div>

		</b-modal>
		<b-modal id="editView" size="md" hide-footer @hidden="hideEditSmartViewModal"  title="Edit view">
			<div class="form-row m-0">
				<div class="form-group col-md-12 pl-0">
					<label for="name">Name <b style="color:red;">*</b></label>
					<input type="text" class="form-control" v-model="smartViewEdit.name" placeholder="" />
					<!--<small style="color: red;"> * Name required</small>-->
					<!--:placeholder="$t('write_the_email_reason_here')"-->
				</div>
				<div class="form-group col-md-12 pl-0">
					<div class="modal-actions">
						<button class="oml-btn outlined gray-btn mr-3" @click="$bvModal.hide('editView')">Cancel</button>   
						<button class="oml-btn" @click="updateSmartView()" >Save</button>
					</div>
				</div>
			</div>
		</b-modal>
		<b-modal id="deleteView" centered hide-footer title="Confirmation">
			<div>
				<div class="row">
					<div class="col-12 mb-1">
						<p class="m-0" style=" color: #000; font-size: 14px; ">{{$t('voulez_vous_vraiment_supprimer')}}</p>
					</div>
				</div>
				<div class="row">
					<div class="col-12 text-right">
						<b-button size="sm"  @click="deleteSmartView()" variant="success">
							<i class="fa fa-spinner fa-spin" v-if="loadingAction"></i> {{$t('smtp_yes_confirmation')}}
						</b-button>
					</div>
				</div>
			</div>
		</b-modal>
	</aside>
</template>
<script>
import draggable from 'vuedraggable'

    export default {
        name: 'leftSidebar',
		components: {
            draggable,
        },
        data() {
            return {
                //
                //sourcesCount	: 0,
                //leadsCountSide	: 0,
                /*
				smartViews:[
					{name:'Pending',val:'14'},
					{name:'Leads to call',val:'20'},
					{name:'Call back',val:'25'},
					{name:'Qualified',val:'30'},
					{name:'Unassigned',val:'8'},
					{name:'Assigned',val:'16'},
					{name:'Hot',val:'26'},
					{name:'This month',val:'33'},
					{name:'This week',val:'40'}
				],
				*/
				smartViews:[],
				loadingAction:false,
				searchView:false,
				showLeadsView:false,
                sidebar_menu: [
                    { title:'LeadSources', fade	: false, icon : 'feather-layers.svg',url : 'mysources',  page:'mySources', children: [] },
                    { title:'MyLeads', 	 fade	: false, icon : 'material-people.svg', 		url : 'myleads',    page:'myLeads', children: [] },
                    { title:'Analytics', fade	: false, icon : 'feather-bar-chart.svg', 	url : 'analytics',  page:'analytics', children: [] },
                    { title:'Destinations', fade	: false, icon : 'destination.svg', 	url : 'destination',  page:'destination', children: [] },
                ],
                sidebar_menu_down: [
                    { title:'Workspace Settings', fade	: false, icon : 'feather-settings.svg', 	url : 'settings',  page:'clients', children: [] },
                    { title:'Your Account', fade	: false, icon : 'user.svg', 	url : 'account',  page:'profile', children: [] },

                ],
                sidebar_menu_white: [
                    { title:'MySources', fade	: false, icon :'wleadsources.svg',url : 'mysources',  page:'mySources', children: [] },
                    { title:'MyLeads', 	 fade	: false, icon :'wleads.svg', 		url : 'myleads',    page:'myLeads', children: [] },
                    { title:'Analytics', fade	: false, icon : 'wanalytics.svg', 	url : 'analytics',  page:'analytics', children: [] },
                    /* {
                        title : 'LeadSources', fade	: true,
                        children: [
                            // {title:'MySources', path:'mysources', page:'mySources', img:'leadsources.svg'},
                            {title:'NewSources', path:'newsource', page:'newSource', img:'plus1.svg'},
                        ]
                    }, */
                    /* {
                        title	: 'LeadManagement',
                        fade	: true,
                        children: [
                            // {title:'MyLeads', 		path:'myleads', 		page:'myLeads', 		img:'leads.svg'},
                            // {title:'Qualification', 	path:'qualification', 	page:'qualification', 	img:'play.svg'},
                            {title:'Analytics', 		path:'analytics', 		page:'analytics', 		img:'analytics.svg'},
                        ]
                    }, */
                    {
                        title	: 'Integrations',
                        fade	: true,
                        icon	: 'wenrichement.svg',
                        // url		: 'integration',
                        children: [
                            {'title':'ESP', 			path:'integration', 	page:'esp', 			img:'wesp.svg'},
                            {'title':'CRMs', 			path:'integration',		page:'crms', 			img:'wCRMs.svg'},
                            // {'title':'VOIP',		 	path:'integration',		page:'voip', 			img:'voip.svg'},
                            // {'title':'LeadEnrichment', 	path:'integration', 	page:'leadenrichment', 	img:'LeadEnrichment.svg'},
                        ]
                    },
                ],
				smartViewEdit:{
					id:0,
					name:'',
				},
				smartViewDelete:{
					id:0,
				},
				searchSmartView:'',
				enabled: true,
            }
        },
        methods: {

            // plans

            leftSidebarInfo() {
                let that = this
                let url   = 'left_sidebar'
                let method= 'GET'
                let data  = {}
                that.$store.dispatch('apiRequest', { url, method, data } ).then((/* response */) => {
                    // console.log(response)
                }).catch(( /* {response} */ ) => {
                    // console.log(response)
                })

            },

            showLeadsViewFun(page) {
                let that = this
                
				if(page == 'myLeads'){
					that.showLeadsView = true
				}else{
					that.showLeadsView = false
				}

            },
			getSmartViews() {
				let that = this;
				let url   = 'filters';
				let method= 'GET';
				let data  = {smartViewsSearch:that.searchSmartView};
				that.smartViews = [];
				that.$store.dispatch('apiRequest', { url, method, data }).then((response) => {
					console.log(data)
					Object.values(response.data.filters).forEach(val => {
						that.smartViews.push({id:val.id,name:val.libelle,filter:val.filter,val:val.total,status:val.status});
					});	
				}).catch((response) => {	
					console.log(response);
				})
			},
			sendFilter(item,key){
				let that = this;
				console.log(key)
				that.$store.state.reloadCompenent = Math.random();
				that.$store.state.checkSmartVienwsFilter = true;
				that.$store.state.smartViewsItem = item;
			},
			editSmartView(item){
				let that = this;
				console.log(that);
				console.log(item);
				if(item){
					that.smartViewEdit.id = item.id;
					that.smartViewEdit.name = item.name;
				}	
			},
			hideEditSmartViewModal(){

			},
			updateSmartView(){
				let that = this;
				let url   = 'update_filter';
				let method= 'POST';
				let data  = {id:that.smartViewEdit.id,name:that.smartViewEdit.name};
				that.$store.dispatch('apiRequest', { url, method, data }).then((response) => {
					console.log(response.data.success);
					if(response.data.success){
						that.getSmartViews();
						that.$bvModal.hide('editView');
					}
				}).catch(({response}) => {	
					console.log(response);
				})
			},
			deleteSmartViewModal(item){
				let that = this;
				console.log(that);
				console.log(item);
				if(item){
					that.smartViewDelete.id   = item.id;
				}	
			},
			deleteSmartView(){
				let that  = this;
				if(that.smartViewDelete.id != 0){
				let url   = 'delete_filter';
				let method= 'DELETE';
				let data  = {id:that.smartViewDelete.id};
				that.$store.dispatch('apiRequest', { url, method, data }).then((response) => {
					if(response.data.success){
							that.getSmartViews();
							that.$bvModal.hide('deleteView');
							that.smartViewDelete.id = 0;
						}
					}).catch(({response}) => {	
						console.log(response);
					})
				}
			},
			search(){
				if (this.timer) {
					clearTimeout(this.timer);
					this.timer = null;
				}
				this.timer = setTimeout(() => {
					console.log('search')
					let that = this;
					that.getSmartViews();
				}, 800);
			},
			close(){
				let that = this;
				that.searchSmartView = '';
				that.getSmartViews();
			},
			saveStatusChanges () {
				let that = this
				let url   = 'leads/sortStatusSmartViews'
				let method= 'POST'
				let data  = {myArrayStatus:that.smartViews, provider : "add status", permissionid : 20} // defaultStatusSelected:that.SelectedStatus, 
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then(( response ) => {
					
					if( response.data.success ) {
						
						that.notifyMe(this.$t('ChangesSaved'), 'success')
						
					} else {
						that.notifyMe(response.data.message, 'warn')
					}

				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')

				})
				
			},
		},
        computed: {
            leadsCountSide () {return this.$store.state.total.leadsCountSide},
            sourcesCount () {return this.$store.state.total.sourcesCount},
			reloadLeftSidebarCompenent () {return this.$store.state.reloadLeftSidebarCompenent},
			checkChange () {return this.$store.state.checkChange},
        },
        updated(){
			this.showLeadsViewFun(this.$store.state.selectedPage);
			if(this.reloadLeftSidebarCompenent && this.checkChange == true ) {
				this.searchSmartView = '';
				this.getSmartViews();
				this.$store.state.checkChange = false;
			}
		},
		filters: {
			maxLength: function (str) {
				if( str != null && str != '' ) {
					return str.length > 9 ? str.substring(0, 9) + "...." : str
				} else {
					return ''
				}
			},
		},
        mounted() {
            this.leftSidebarInfo();
            this.$store.state.selectedPage = this.$route.name;
            // let that = this
            // setTimeout( function () {
            // that.$store.state.WhiteLabel = true
            // }, 3000 )
            // console.log( this.$route.name )
			//this.getSmartViews();
        },
    }
</script>

<style lang="scss">
	.bg-dark-bule-2 {
		background: #1d2841;
		background-image: linear-gradient(150deg,#202B49 0%,#344979 100%);
	}
	.li-tab-md {
		display: block;
		.text-li-tab {

			vertical-align: middle;
			color: #878e9e;
			font-size: 15px;
			font-weight: 700;

		}
	}

	.btn-newsource {
		height: 40px;
		padding: 9px 0px;
		margin-bottom: 0.5rem !important;
	}

	span.badge-md {
		background: #4caf50;
		position: relative;
		right: 1px;
		top: 1px;
		font-weight: 500;
		font-size: 10px;
		float: right;
		color: #fff;
		line-height: 20px !important;
		width: auto;
		min-width: 20px;
		height: 20px;
		text-align: center;
		border-radius: 20px;
		padding: 0 5px;
	}

	.oml-leftSidbar-nav{
		height: calc(100vh - 80px);
		display:flex;
		flex-direction:column;
		align-items:start;
		justify-content:space-between;
		overflow-x: hidden;
		overflow-y: auto;
		width:100%;

		&::-webkit-scrollbar-thumb{
			background-color:transparent !important;
		}
	}

	.nav-scrollable-md {
		padding-left: 8px;
		padding-right: 8px;
		margin-top: 0;
		list-style: none;
		padding-top: 36px;
		padding-bottom: 30px;
		width:100%;

		>li {
			margin-bottom: -2px;
		}

		li{
			@media screen and (max-height:700px){
				div.pt-3{
					padding-top:5px !important;
				}
			}
		}
		
		&.oml-bottomNav{
			padding-top:20px;
			padding-bottom:0px;
			position:absolute;
			bottom:0;
			left:0;

			a{
				padding-bottom:0;
			}
		}
	}

	.menu-collapse-md {
		margin: 24px 0 0 0;
		/* padding-bottom: 5px; */
		text-transform: uppercase;
		font-size: 14px;
		/* opacity: .5; */
		font-weight: 600;
		cursor: pointer;
		// color: #eee;

		animation: fadeout 1s ;
		-webkit-animation: fadeout 1s ; /* Chrome, Safari, Opera */
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;

		.arrow-up {
			width: 15px;
			margin-right: -4px;
			margin-top: -1px;
		}

	}
	.menu-items-md {

		animation: fadeout 1s ;
		-webkit-animation: fadeout 1s ; /* Chrome, Safari, Opera */
		animation-fill-mode: forwards;
		-webkit-animation-fill-mode: forwards;

		list-style: none;
		padding-left: 0px;
		padding-bottom: 11px;

		&ul {
			padding-top: 10px;
		}

		li {
			padding-top: 15px;
		}

		.menu-title {
			padding-left: 6px;
			/* color: #dedede; */
			font-size: 13px;
			width: 86%;
			display: inline-block;

			&.active {
				color: #fff;
				font-weight: 600;
				position: relative;
			}

			&:hover {
				color: #01aaf4;
			}

			&.active:after {
				right: -15px;
				top: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
			}

			&.active:after {
				border-color: rgba(255, 255, 255, 0);
				border-right-color: #ffffff;
				border-width: 10px;
				margin-top: -10px;
			}

		}

		.menu-icon {
			color: #fff;
			display:inline-block;
			width:24px;

			i.fa {
				width: 20px;
				display: inline-block;
			}
			img {
				max-width: 24px;
				max-height: 24px;
				min-width:16px;
			}
		}
	}

	.vbox>header~section {
		top: 80px !important;
	}

	.btn-container {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.aside-xl {
		width: 200px !important;
		max-width: 200px !important;
	}
	#content {
		width: calc(100% - 200px);
	}

	.quillWrapper #content {
		width:100%;
	}

	@-moz-document url-prefix() {
		#content {
			width: calc(100% - 205px);
		}
		.hbox {
			display: block;
		}
	}
	.btn-container a.btn {
		background: #FFF;
		color: #2f426a !important;
		border-color: #FFF;
		padding: 1rem;
		margin-top: 2rem;
		display: flex;
		justify-content: space-evenly;
	}

	.btn-container img {
		width: 20px;
		height: 20px;
	}

	#nav .nav {
		padding-left: 1.5rem;
		padding-right: 1rem;
		margin-top: 0rem;
	}

	/* #nav h6 {
        margin: 2rem 0 1.5rem 0;
        text-transform: uppercase;
        font-size: 1rem;
        opacity: .5;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    } */

	/* #nav h6 img {
        width: 15px;
    } */

	#nav .divider {
		display: none;
	}

	#nav .menu-item {
		display: flex;
		align-items: center;
	}

	#nav .menu-item img {
		width: 20px;
	}

	#nav .badge-lead {
		margin-top: 0;
	}

	#nav .menu-items {
		display: flex;
		padding: 6px 0px;
		align-items: center;
		justify-content: space-between;
	}

	#menuToggle {
		display: flex;
		align-items: center;
		padding-left: 1.4rem;
		font-size: 12px;
	}

	#menuToggle img {
		width: 16px;
		margin-right: .5rem;
	}

	.nav.no-padding {
		padding: 0 0 !important;
		margin-top: 0 !important;
	}

	h6.collapsed img {
		transform: rotateZ(-180deg);
	}
	
	
	.oml-smartViews-sidebar{
		border-top:1px solid #485981;
		border-bottom:1px solid #485981;
		padding:15px 0 15px 20px;
		margin:0 -20px;
		
		.oml-smartViewsSidebar-header{
			display:flex;
			align-items:center;
			justify-content:space-between;
			padding-right:50px;
			position:relative;
			
			h6{
				font-size:11px;
				font-weight:300;
				margin:0;
			}
			
			button{
				background-color:#42537c;
				border:0px solid transparent;
				width:25px;
				height:25px;
				border-radius:100%;
				color:#99a2b8;
				display:flex;
				align-items:center;
				justify-content:center;
			}
			
			.oml-smartViewsSidebar-search{
				position:absolute;
				top:0;
				left:0;
				background-color:#3a4c73;
				border:1px solid #3a4c73;
				border-radius:5px;
				display:flex;
				align-items:center;
				width: calc(100% - 50px);
				
				&:before{
					content:"\F0D1";
					font-family: remixicon!important;
					font-style: normal;
					-webkit-font-smoothing: antialiased;
					color:#949caf;
					font-size:14px;
					position:absolute;
					top:0;
					left:5px;
					bottom:0;
					height:21px;
					margin:auto;
				}
				
				input{
					width:100%;
					background-color:transparent;
					border:0px solid transparent;
					color:#949caf;
					font-size:12px;
					padding-left:25px;
					
					&::placeholder{
						color:#949caf;
						font-size:12px;
					}
				}
				
				button{
					padding:0;
					margin:0;
					background-color:transparent;
					border:0px solid transparent;
					color:#949caf;
				}
			}
		}
		
		.oml-smartViewsSidebar-content{
			max-height:130px;
			overflow-y:auto;

			@media screen and (max-height:625px){
				max-height:105px;
			}
			
			.oml-smartViewsSidebar-item{
				display:flex;
				align-items:center;
				justify-content:space-between;
				padding:2px 5px;
				margin:0 5px 0 -5px;
				min-height:32px;

				&.default-item{
					//background-color:#2c375b;
				}
				
				.oml-smartViews-left{
					display:flex;
					align-items:center;
					
					i{
						color:#788298;
						font-size:12px;
						margin-right:5px;
						opacity:0;
					}
					
					span{
						font-size:12px;
						font-weight:300;
					}
				}
				
				.oml-smartViews-right{
					display:flex;
					align-items:center;
					
					
					
					span{
						font-size:11px;
						font-weight:400;
					}
					
					button{
						background-color:transparent;
						border:0px solid transparent;
						color:#788298;
						opacity:0;
						cursor:initial;
						padding:0 0 0 4px;
						min-width:20px;
					}
				}
				
				&:hover{
					background-color:#27375b;
					border-radius:5px;
					
					.oml-smartViews-left{
						i{
							opacity:1;
						}
					}
					
					.oml-smartViews-right{
						button{
							opacity:1;
							cursor:pointer;
						}
					}
				}

				&.active{
					background-color:#27375b;
					border-radius:5px;
				}
			}
		}
		
		.oml-smartViewsSidebar-showMore{
			background-color:transparent;
			border:0px solid transparent;
			border-radius:5px;
			color:#fff;
			display:flex;
			align-items:center;
			justify-content:center;
			width: calc(100% - 20px);
			padding:5px 10px;
			
			span{
				display:flex;
				align-items:center;
				font-size:12px;
				font-weight:400;
				
				i{
					font-size:12px;
					margin-right:5px;
				}
			}
			
			&:hover{
				background-color:#536182;
			}
		}
	}
	
	#editView{
		.dropdown-menu{
			li{
				a{
					color:red;
				}
			}
		}
	}
	
	.slide-fade-enter-active {
		transition: all .3s ease;
	}
	.slide-fade-leave-active {
		transition: all .3s ease;
	}
	.slide-fade-enter, .slide-fade-leave-to{
		transform: translateX(40px);
		opacity: 0;
	}
</style>
