<template>
    <div class="Lead-alert-tabs">
		<p>{{ $t('LeadAlertTitle') }}</p>
		
		<div>
			<div class="form-group d-none">
				<label for="subjectNotification">{{ $t('Subject') }}</label>
				<input type="text" id="subjectNotification" v-model="notificationArray.subject" class="form-control" :placeholder="$t('NotificationSubject')" required>
			</div>

			<div class="form-group">
				<label for="emails">{{ $t('SendTo') }}</label>
				<vue-tags-input :placeholder="$t('TypeRecipientEmail')" @tags-changed="addRecipient"
					:allow-edit-tags="true" v-model="recipient" :tags="notificationArray.recipients" id="emails"/><!--  :validation="validateEmail" -->
			</div>

			<div class="row d-none">
				<div class="col-xs-12 col-sm-6">
					<div class="form-group">
						<label for="ReplyToEmail">{{ $t('Replytoemail') }}</label>
						<input type="text" id="ReplyToEmail" v-model="notificationArray.from_email" class="form-control" :placeholder="$t('EmailAddressWillReplyTo')">
					</div>
				</div>
				<div class="col-xs-12 col-sm-6">
					<div class="form-group">
						<label for="replyToName">{{ $t('Replytoname') }}</label>
						<input type="text" id="replyToName" v-model="notificationArray.from_name" class="form-control" :placeholder="$t('TheSenderEmail')">
					</div>
				</div>
			</div>
			
			<div class="form-group d-none">
				<label for="content">{{ $t('Message') }}</label>
				<vue-editor id="content" :editorToolbar="customToolbar" v-model="notificationArray.content"></vue-editor>
			</div>
		</div>
    </div>
</template>

<script>
	// import {mapGetters} from 'vuex'
	import {VueEditor} from 'vue2-editor'
	import vueTagsInput from '@johmun/vue-tags-input';
	// import {ErrorBag, Validator} from 'vee-validate'
	// import mapKeys from 'lodash/mapKeys'
	
	// import axios from 'axios'
	
    export default {
        name: 'lead-alert',
		props: {
			notificationArray: Object
		},
		data() {
			return {
                recipient: '',
                saving: false,
                customToolbar: [
					
					["bold", "italic", "underline"],
					[{ header: [6, 5, 4, 3, 2, 1, false] }],
					[{ 'align': [] }],
					[ { list: "ordered" }, { list: "bullet" }], 
					[{ indent: "-1" }, { indent: "+1" }],
					[ { color: ['#000000', '#663d00', '#f5523c', '#377dff', '#ccc', '#ffc107', '#28a745'] }, { background: [] }],
					[{ 'font': [] }],
					
				],
                /* validateEmail: [
                    {
                        type: 'email',
						// rule: '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/',
						rule: /^.{8,}$/,
                        disableAdd: true,
                    }
                ], */
                errors: '' /* new ErrorBag() */,
                /* loading: false */
            }
        },
        components: {
            VueEditor,
            vueTagsInput
        },
        mounted() {
			
        },
        methods: {
			addRecipient(newRecipients) {
                this.notificationArray.recipients = newRecipients;
            },
        },
        computed: {
            // ...mapGetters({
                // hasNotification: 'sources/getNotificationExistence',
            // }),
            isActive() {
				return this.hasNotification && this.notificationArray.active
            }
        },
    }
</script>

<style scoped lang="scss">
    .Lead-alert-tabs {
		.modal-header {
			justify-content: flex-start;
		}

		.modal-title {
			font-size: 1.3em;
		}

		select, input {
			min-height: 40px;
		}

		.disable-modification {
			pointer-events: none;
			opacity: 0.5;
		}

		.spinner {
			margin-bottom: -5px;
			margin-left: 5px;
		}

		.vue-tags-input {
			max-width: 100% !important;

			.input{
				border-radius: 4px;
			}
			.tag{
				background-color: rgba(119, 131, 143, 0.1) !important;
				color: #77838f !important;
				.content{
					height: auto !important;
				}
			}
		}
    }
	
</style>