import { render, staticRenderFns } from "./LeadAlertTabs.vue?vue&type=template&id=35ed2751&scoped=true&"
import script from "./LeadAlertTabs.vue?vue&type=script&lang=js&"
export * from "./LeadAlertTabs.vue?vue&type=script&lang=js&"
import style0 from "./LeadAlertTabs.vue?vue&type=style&index=0&id=35ed2751&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ed2751",
  null
  
)

export default component.exports